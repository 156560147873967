import { Link } from "react-router-dom";
import './style.sass';

function NavBar(prop: object) {
    return (
        <nav>
            <Link to="/champions"><h3 className="navbar-item">Champions</h3></Link>
            <Link to="/songs"><h3 className="navbar-item">Songs</h3></Link>
        </nav>
    )
}

export default NavBar;
