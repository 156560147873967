

export interface BackendClient {
    fetchSongs(): Promise<Song[]>
    addSong(song: CreateSong): Promise<Response>
    deleteSong(songId: number): Promise<Response>
    linkSong(songId: number, championId: number): Promise<Response>
    fetchLinksBySong(): Promise<ChampionsBySong[]>
    fetchLinksByChampion(): Promise<SongsByChampion[]>
    deleteLink(songId: number, championId: number): Promise<Response>
    youtubeSearch(q: string): Promise<Response>
}

interface BackendProviderOptions {
    apiAddress: string, authorizationToken?: string
}

type IBackendProvider = (options: BackendProviderOptions) => BackendClient

export const BackendProvider: IBackendProvider = (options: BackendProviderOptions): BackendClient => {
    return (() => {
        const authorizationToken: string | null = options.authorizationToken ?? null;
        const apiAddress = options.apiAddress;

        return {
            fetchSongs: async () => 
                fetch(`${apiAddress}/song`)
                    .then(res => res.json()) as Promise<Song[]>,

            addSong: async (song: CreateSong) =>
                fetch(`${apiAddress}/song`, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Authorization': `Bearer ${authorizationToken}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(song)
                }),

            deleteSong: async (songId: number) =>
                fetch(`${apiAddress}/song/${songId}`, {
                    method: 'DELETE',
                    mode: 'cors',
                    headers: {
                        'Authorization': `Bearer ${authorizationToken}`,
                        'Content-Type': 'application/json'
                    }
                }),
            linkSong: async (songId: number, championId: number) =>
                fetch(`${apiAddress}/songlink`, {
                    method: 'PUT',
                    mode: 'cors',
                    headers: {
                        'Authorization': `Bearer ${authorizationToken}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ songId, championId })
                }),
            fetchLinksByChampion: async () => 
                fetch(`${apiAddress}/songlink/by-champion`, {
                    method: 'GET',
                    mode: 'cors'
                })
                    .then(res => res.json()) as Promise<SongsByChampion[]>,
            fetchLinksBySong: async () => 
                fetch(`${apiAddress}/songlink/by-song`, {
                    method: 'GET',
                    mode: 'cors'
                })
                    .then(res => res.json()) as Promise<ChampionsBySong[]>,
            deleteLink: async (songId: number, championId: number) =>
                fetch(`${apiAddress}/songlink`, {
                    method: 'DELETE',
                    mode: 'cors',
                    headers: {
                        'Authorization': `Bearer ${authorizationToken}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ songId, championId })
                }),
            youtubeSearch: async (q: string) =>
                fetch(`${apiAddress}/ytproxy?q=${q}`, {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Authorization': `Bearer ${authorizationToken}`,
                        'Content-Type': 'application/json'
                    }
                })
        }
    })();
}
