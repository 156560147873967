import React, { useState } from 'react';
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import './App.sass';
import { AutoLogin } from './components/LogIn/AutoLogin';
import LogIn from './components/LogIn/LogIn';
import LolDataLoader from './components/LolDataLoader/LolDataLoader';
import NavBar from './components/NavBar/NavBar';
import ChampionsPage from './components/Pages/Champions/ChampionsPage';
import SongsPage from './components/Pages/Songs/SongsPage';
import { AuthContext } from './contexts/AuthContext';
import { ILolDataContext, LolDataContext } from './contexts/LolDataContext';

function App() {
  const [auth, setAuth] = useState<string | null>(null);
  const initAuth = {apiToken: auth, setApiToken: setAuth}

  const [lolChampionData, setLolChampionData] = useState<ChampionData>({});
  const [version, setVersion] = useState<string | null>(null);
  const initLolData: ILolDataContext = {
    champions: lolChampionData,
    setData: setLolChampionData,
    version: version,
    setVersion: setVersion
  }

  return (
    <div className="background">
      <div className="page">
        <AuthContext.Provider value={initAuth}>
          <LolDataContext.Provider value={initLolData}>
            <AuthContext.Consumer>
              { context => <AutoLogin {...context} /> }
            </AuthContext.Consumer>
            <LolDataContext.Consumer>
              { context => <LolDataLoader {...context} /> }
            </LolDataContext.Consumer>

            <Router>
              <h1 className="hor-centered main-title"><Link to="/">Bot Music Manager</Link></h1>
              <NavBar />
              
              <div className="content">
                <div className="content-page">
                  <Routes>
                    <Route path="/champions" element={<ChampionsPage />} />
                    <Route path="/songs" element={<SongsPage />} />
                    <Route path="/" element={<LogIn />} />
                  </Routes>
                </div>
              </div>
            </Router>
            
          </LolDataContext.Provider>
        </AuthContext.Provider>
      </div>
    </div>
  );
}

export default App;
export { AuthContext }
