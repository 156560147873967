import { useEffect } from "react";
import { IAuthContext } from "../../contexts/AuthContext";


export const storageKey = 'AUTHORIZATION_TOKEN';

export function AutoLogin(props: IAuthContext) {

    const tryLoginFromSessionstorage = () => {
        if (props.apiToken !== null) return;
    
        const token = sessionStorage.getItem(storageKey);
        if (token === null) return;
    
        props.setApiToken(token);
    }

    useEffect(tryLoginFromSessionstorage, [props]);

    return null
}


