import React from "react"

export type ISetData = (data: ChampionData) => void
export type ISetVersion = (version: string) => void
export interface ILolDataContext {
    champions: ChampionData
    version: string | null
    setData: ISetData
    setVersion: ISetVersion
}
  
export const LolDataContext = React.createContext<ILolDataContext>({
    champions: {},
    version: null,
    setData(_) {},
    setVersion(_) {}
});
