import './style.sass'

interface ModalProp {
    element: JSX.Element
    onCancel(): void
}

function Modal(prop: ModalProp) {
    return (
        <div className="modal-frame">
            
            <div className="modal-element">
                {prop.element}
            </div>
            <button className="modal-close-button" onClick={prop.onCancel}></button>
        </div>
    )
}

export default Modal
