import { useEffect, useState } from 'react';
import { BackendProvider } from '../../api/ApiProvider';
import { AuthContext } from '../../App';
import { LolDataContext } from '../../contexts/LolDataContext';
import { ChampionPicker } from './ChampionPicker/ChampionPicker';
import Modal from '../Modal/Modal';
import Song from '../Song/Song';
import YoutubeSearch from '../Youtube/Search/YoutubeSearch';

import './style.sass'

const apiAddress: string = process.env.REACT_APP_API_ADDRESS ?? '';
interface SongLinkState {
    songId: number | null
    songName: string | null
}

function SongCollection(prop: object) {
    const [songs, setSongs] = useState<Song[]>([]);
    const [addingSong, setAddingSong] = useState<boolean>(false);
    const [linkingSong, setLinkingSong] = useState<SongLinkState>({ songId: null, songName: null });
    const [songFilter, setSongFilter] = useState<string>('');
    const [championsBySong, setChampionsBySong] = useState<ChampionsBySong[]>([]);
    const [changeFlag, setChangeFlag] = useState<number>(0);

    const updateSongs = () => {
        BackendProvider({ apiAddress })
            .fetchSongs()
            .then(setSongs);
    }

    const updateLinks = () => {
        BackendProvider({ apiAddress })
            .fetchLinksBySong()
            .then(setChampionsBySong);
    }

    useEffect(() => {
        updateSongs();
        updateLinks();
    }, [changeFlag]);

    const handleSelect = (item: Item, apiToken: string, note?: string) => {
        const song: CreateSong = {
            name: item.snippet.title,
            link: `https://youtu.be/${item.id.videoId}`,
            sourceId: 1,
            note: note ?? null
        };

        addSong(song, apiToken);
        return true;
    }

    const addSong = (item: CreateSong, authorizationToken: string) => {
        BackendProvider({ apiAddress, authorizationToken })
            .addSong(item)
            .then(() => {
                updateSongs();
            });
    }

    const deleteSong = (songID: number, authorizationToken: string) => {
        BackendProvider({ apiAddress, authorizationToken })
            .deleteSong(songID)
            .then(() => updateSongs());
    }

    const startLinkingSong = ({ id: songId, name: songName }: Song) => {
        setLinkingSong({ songId, songName })
    }

    const linkCurrentSong = (championKey: number, authorizationToken: string): void => {
        if (linkingSong.songId === null) return;

        BackendProvider({ apiAddress, authorizationToken })
            .linkSong(linkingSong.songId, championKey)
            .then(() => setChangeFlag(changeFlag + 1));
    }

    const removeLink = (championKey: number, authorizationToken: string): void => {
        if (linkingSong.songId === null) return;

        BackendProvider({ apiAddress, authorizationToken })
            .deleteLink(linkingSong.songId, championKey)
            .then(() => setChangeFlag(changeFlag + 1));
    }

    return (
        <div className="collection-frame">
            <h1 className="hor-centered">Songs currently saved in application</h1>
            <div className="song-search-frame">
                <input type="text" className="song-search-input" placeholder="Search" value={songFilter} onChange={e => setSongFilter(e.target.value)}></input>
                <button type="button" className="song-add-button" title="Add song from Youtube" onClick={() => setAddingSong(true)}></button>
            </div>
            <div className="song-list-frame">
            { songs
                .filter(song => (songFilter.length === 0 || song.name.search(new RegExp(songFilter, 'i')) >= 0))
                .map((song, index) => (
                    <AuthContext.Consumer key={index}>
                        {({ apiToken }) => 
                            <Song {...song}
                                onDelete={() => deleteSong(song.id, apiToken ?? '')}
                                onChampionClick={() => startLinkingSong(song)}
                                isRemoveDisabled={championsBySong.some(cs => cs.song.id === song.id && cs.championIds.length > 0)} />
                        }
                    </AuthContext.Consumer>
                )) }
            </div>
            { addingSong &&
                <Modal element={
                    <AuthContext.Consumer>
                        {({ apiToken }) => (
                            <YoutubeSearch authorizationToken={apiToken ?? ''} onSubmit={(item, note) => handleSelect(item, apiToken ?? '', note)}/>
                        )}
                    </AuthContext.Consumer>
                    
                } onCancel={() => setAddingSong(false)} />
            }
            { linkingSong.songId !== null &&
                <Modal element={
                    <AuthContext.Consumer>
                        { ({ apiToken }) => (
                            <LolDataContext.Consumer>
                                { context => (
                                    <ChampionPicker
                                        data={context.champions}
                                        apiVersion={context.version}
                                        songName={linkingSong.songName ?? 'NULL'}
                                        onPick={(key) => linkCurrentSong(key, apiToken ?? '')}
                                        linkedChampions={championsBySong.find(link => link.song.id === linkingSong.songId)?.championIds.map(id => Object.values(context.champions).find(champion => parseInt(champion.key) === id)) ?? []}
                                        onRemoveLink={(key) => removeLink(key, apiToken ?? '')}
                                    />
                                )}
                        </LolDataContext.Consumer> )}
                    </AuthContext.Consumer>
                    
                } onCancel={() => setLinkingSong({ songId: null, songName: null })} />
            }
        </div>
    )
}

export default SongCollection;
