import { decodeHtml } from '../Youtube/Result/YoutubeSearchResult';
import YoutubeIcon from '../Youtube/YoutubeIcon';
import './style.sass'

interface SongProp extends Song {
    isRemoveDisabled: boolean
    onDelete(): void
    onChampionClick(): void
}

function Song(prop: SongProp) {
    return (
        <div className="song-frame">
            <button onClick={prop.onChampionClick} className="song-champion-button" title={`Link '${prop.name}' to champions`}></button>
            <button className="song-remove-button" title={`Remove '${prop.name}'`} onClick={prop.onDelete} disabled={prop.isRemoveDisabled}></button>
            <a href={prop.link} target="_blank" rel="noreferrer" className="song-link">
                { prop.source.name.toLowerCase() === 'youtube' &&
                    <YoutubeIcon width="1.1em"/>
                }
                <p>{decodeHtml(prop.name)}</p>
            </a>
        </div>
    )
}

export default Song
