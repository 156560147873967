import "./style.sass"
export type State = 'ASC' | 'DESC' | null

interface OrderButtonProps {
    label: string
    state: State
    onClick(state: State): void
}

export function OrderButton(props: OrderButtonProps) {

    const toggleState = (old: State): State => {
        const s: State = old === 'ASC' ? 'DESC' : 'ASC';
        return s;
    }

    return (
        <button type="button" className={`order-button ${props.state}`} onClick={() => props.onClick(toggleState(props.state))} >{props.label}</button>
    )
}