import "./style.sass"

interface ChampionProps {
    icon: JSX.Element
    songCounter: number | null
    onClick(): void
}

export function Champion(props: ChampionProps) {
    return (
        <div className="champion-frame" onClick={props.onClick}>
            { props.icon }
            <div className="champion-songcounter">
                <span>{ props.songCounter === null ? '...' : props.songCounter }</span>
            </div>
        </div>
    )
}