import { useEffect } from "react"
import { ILolDataContext } from "../../contexts/LolDataContext"
import "./style.sass"

//#region Fetch version
const fetchLatestVersion = async (): Promise<string> => {
    return await fetch('https://ddragon.leagueoflegends.com/api/versions.json')
        .then(res => res.json())
        .then((body: string[]) => body[0]);
}

const fetchVersionIfEmpty = async (props: ILolDataContext): Promise<void> => {
    if (props.version == null) {
        return await fetchLatestVersion()
            .then(version => props.setVersion(version));
    }
}
//#endregion
//#region Fetch champions
const fetchChampions = async (version: string): Promise<ChampionData> => {
    return await fetch(`https://ddragon.leagueoflegends.com/cdn/${version}/data/en_US/champion.json`)
        .then(res => res.json())
        .then(body => body.data as ChampionData);
}

const fetchChampionsAndVersionsIfEmpty = async (props: ILolDataContext): Promise<void> => {
    await fetchVersionIfEmpty(props);
    if (props.version != null && Object.keys(props.champions).length === 0)
        await fetchChampions(props.version)
            .then(champs => props.setData(champs));
}
//#endregion

function LolDataLoader(props: ILolDataContext) {

    

    useEffect(() => {
        fetchChampionsAndVersionsIfEmpty(props);
    }, [props]);

    return null
}

export default LolDataLoader
