import { FormEvent, useState } from 'react';
import './style.sass'
import Result from '../Result/YoutubeSearchResult'
import { BackendProvider } from '../../../api/ApiProvider';

interface YoutubeSearchProp {
    preFill?: string
    authorizationToken: string
    onSubmit(selected: Item, note?: string): boolean
}
const apiAddress: string = process.env.REACT_APP_API_ADDRESS ?? '';

const ytRegexps: RegExp[] = [new RegExp("youtube\\.com.*v=([\\w\\d-_]+)", "i"), new RegExp("youtu\\.be\\/([\\w\\d-_]+)", "i")];

function YoutubeSearch(prop: YoutubeSearchProp) {
    const [searchParam, setSearchParam] = useState<string>(prop.preFill ?? '');
    const [results, setResults] = useState<Item[]>([]);
    const [customLinkV, setCustomLinkV] = useState<string | null>(null);
    const [customTitle, setCustomTitle] = useState<string>('');
    
    const search = (el: FormEvent) => {
        BackendProvider({ apiAddress, authorizationToken: prop.authorizationToken })
            .youtubeSearch(searchParam)
            .then(res => res.json())
            .then(body => setResults(body.items));
        el.preventDefault();
    }

    const onCustomLinkChange = (value: string) => {
        let isLinkValid: boolean = false;

        ytRegexps.forEach(regexp => {
            if (value.search(regexp)) {
                var found = value.match(regexp);
                if (found !== null) {
                    console.log(found[1])
                    setCustomLinkV(found[1]);
                    isLinkValid = true;
                    return;
                }
            }
        });

        if (!isLinkValid)
            setCustomLinkV(null);
    }

    return (
    <div className="youtube-frame">
        <h1 className="hor-centered">Youtube</h1>
        <div className="flex">
            <form className="controls" onSubmit={search} >
                <input className="youtube-search-input" type="search" autoComplete="on" placeholder="Paste link or search" autoFocus={true}
                       value={searchParam} onChange={e => setSearchParam(e.target.value)} id="youtube-search-input"></input>
                <button className="youtube-search-button" type="submit">Search</button>
            </form>
            { (results && results.length > 0) &&
                <div className="youtube-results">
                    { results.map((result, index) => {
                        return <Result {...result} key={index} onClick={() => prop.onSubmit(result)} />
                    }) }
                    <div style={{ backgroundColor: "rgba(120, 214, 120, .5)", borderRadius: "6px", marginTop: "0.5em", display: "inline-block", padding: "0 1em" }} >Green = Added</div>

                </div>
            }
        </div>
        <hr />
        <div className="manual-add">
            <h4>Or add a song manually</h4>
            <input type="text" placeholder="Song title" value={customTitle} onChange={(e) => setCustomTitle(e.target.value)}/>
            <input type="text" placeholder="Youtube link" onChange={(e) => onCustomLinkChange(e.target.value)} className={ customLinkV === null ? 'bad-value' : 'good-value' } />
            <button type="button" disabled={customLinkV === null} onClick={() => prop.onSubmit({ id: { videoId: (customLinkV ?? '')}, snippet: { title: customTitle } }, 'Added manually')} >Add</button>
        </div>
    </div>
    )
}

export default YoutubeSearch;
