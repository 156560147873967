import { useEffect, useState } from "react"
import { BackendProvider } from "../../../api/ApiProvider";
import { IAuthContext } from "../../../contexts/AuthContext";
import { ILolDataContext } from "../../../contexts/LolDataContext"
import { ChampionIcon } from "../../ChampionIcon/ChampionIcon"
import { decodeHtml } from "../../Youtube/Result/YoutubeSearchResult";
import "./style.sass"

interface SongPickerProps {
    champion: Champion | undefined
    selectedSongs: Song[]
    dataContext: ILolDataContext
    authContext: IAuthContext
    onDataUpdate(): void
}

const apiAddress: string = process.env.REACT_APP_API_ADDRESS ?? '';

export function SongPicker(props: SongPickerProps) {
    const [songs, setSongs] = useState<Song[]>([]);
    const [filter, setFilter] = useState<string>('');

    const removeLink = (songId: number) => {
        BackendProvider({ apiAddress, authorizationToken: props.authContext.apiToken ?? '' })
            .deleteLink(songId, parseInt(props.champion?.key ?? '-1'))
            .then(() => props.onDataUpdate())
    }

    const addLink = (songId: number) => {
        BackendProvider({ apiAddress, authorizationToken: props.authContext.apiToken ?? '' })
            .linkSong(songId, parseInt(props.champion?.key ?? '-1'))
            .then(() => props.onDataUpdate())
    }

    useEffect(() => {
        BackendProvider({ apiAddress })
            .fetchSongs()
            .then(res => setSongs(res));
    }, [])

    if (props.champion === undefined) return null

    return (
        <div className="songpicker-frame">
            <ChampionIcon {...props.champion} version={props.dataContext.version ?? ''} style={{ width: '3em', display: 'inline-block' }} />
            <h1>{props.champion.name}</h1>
            <div>
                { (props.selectedSongs.length > 0) &&
                    <div>
                        <h3>Has songs:</h3>
                        { props.selectedSongs.map((song, index) => (
                            <div key={index} className="songpicker-selected-song">
                                <button type="button" className="songpicker-removelinkbutton" onClick={() => removeLink(song.id)} />
                                <span><a href={song.link} target="_blank" rel="noreferrer">{decodeHtml(song.name)}</a></span>
                            </div>
                        ))}
                    </div>
                }
                
                <h3>Add song:</h3>
                <input className="songpicker-filter" placeholder="Search" onChange={(e) => setFilter(e.target.value)} value={filter} />
                <div className="songpicker-list">
                    { songs.filter(song => filter.length === 0 || song.name.search(new RegExp(filter, 'i')) >= 0).map((song, index) => (
                        <div className="songpicker-song" key={index} onClick={() => addLink(song.id)}>
                            {decodeHtml(song.name)}
                        </div>
                    ))
                    }
                </div>
                
            </div>
            
            
        </div>
    )
}