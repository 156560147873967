import { useState } from "react";
import { ChampionIcon } from "../../ChampionIcon/ChampionIcon";
import { decodeHtml } from "../../Youtube/Result/YoutubeSearchResult";
import "./style.sass"

interface ChampionPickerProps {
    data: ChampionData
    apiVersion: string | null
    songName: string
    linkedChampions: (Champion | undefined)[]
    onPick(key: number): void
    onRemoveLink(key: number): void
}

export function ChampionPicker(props: ChampionPickerProps) {
    const [championFilter, setChampionFilter] = useState<string>('');
    
    return (
        <div className="championpicker-frame">
            <h1>{decodeHtml(props.songName)}</h1>
            { props.linkedChampions.length > 0 &&
                <div>
                    <h4>Is currently linked to (click to remove):</h4>
                    { props.linkedChampions.filter(champion => champion !== undefined).map((champion, index) => (
                        <div key={index} className="championpicker-existing" onClick={() => props.onRemoveLink(parseInt(champion?.key ?? '-1'))}>
                            <ChampionIcon {...champion as Champion} version={props.apiVersion ?? ''} style={{ width: '3em' }}/>
                        </div>
                    ))}
                </div>
            }
            
            <div className="champion-search-frame">
                <h4>Link to</h4>
                <input value={championFilter} autoFocus={true} onChange={(e) => setChampionFilter(e.target.value)} placeholder="champion name"/>
            </div>
            
            <div className="championlist">
                { props.apiVersion != null &&
                  Object.values(props.data)
                    .filter(value => championFilter.length === 0 || value.name.search(new RegExp(championFilter, 'i')) >= 0)
                    .map(value => (
                        <div key={value.id} onClick={() => props.onPick(parseInt(value.key))}>
                            <ChampionIcon version={props.apiVersion as string} id={value.id} name={value.name} style={{width: '3em'}} />
                        </div>
                    ))}
            </div>
            
        </div>
    )
}
