import icon from '../../assets/youtube-icon.jpg';

function YoutubeIcon(prop: { width?: string }) {
    return (
        <img src={icon} alt="Youtube icon" style={{ width: prop.width ?? '2em', height: prop.width ?? '2em'}}></img>
    )

}

export default YoutubeIcon
