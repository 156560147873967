import { useEffect, useState } from "react";
import { BackendProvider } from "../../api/ApiProvider";
import { IAuthContext } from "../../contexts/AuthContext";
import { ILolDataContext } from "../../contexts/LolDataContext";
import { ChampionIcon } from "../ChampionIcon/ChampionIcon";
import Modal from "../Modal/Modal";
import { OrderButton, State } from "../OrderButton/OrderButton";
import { Champion } from "./Champion/Champion";
import { SongPicker } from "./SongPicker/SongPicker";
import "./style.sass"

interface ChampionCollectionProps {
    authContext: IAuthContext
    dataContext: ILolDataContext
}

type Order = 'Name' | 'Songs'
const orders: Order[] = ['Name', 'Songs']

type Orders = { label: Order, state: State }[]

const sortByName = (isDesc?: boolean) => (first: Champion, second: Champion) => {
    let a = isDesc ? first : second;
    let b = isDesc ? second : first;
    return b.name.localeCompare(a.name);
}

const sortBySongs = (links: SongsByChampion[], isDesc?: boolean) => (first: Champion, second: Champion) => {
    let a = isDesc ? first : second;
    let b = isDesc ? second : first;
    return (links.find(song => song.championId === parseInt(a.key))?.songs.length ?? 0) - (links.find(song => song.championId === parseInt(b.key))?.songs.length ?? 0);
}

const apiAddress: string = process.env.REACT_APP_API_ADDRESS ?? '';


export function ChampionCollection(props: ChampionCollectionProps) {
    const [orderState, setOrderState] = useState<Orders>(orders.map(order => { return { label: order, state: null }}))
    const [filter, setFilter] = useState<string>('');
    const [links, setLinks] = useState<SongsByChampion[] | null>(null);
    const [modalChampionId, setModalChampionId] = useState<number | null>(null);
    const [changeFlag, setChageFlag] = useState<number>(0);

    const handlerOrderClick = (selectedOrder: Order, state: State): void => {
        const newState = [...orderState];
        newState.forEach(order => { order.state = null });
        const selected = newState.find(order => order.label === selectedOrder);
        if (selected) selected.state = state;
        setOrderState(newState);
    }

    const getOrderAlgorithm = (orderState: Orders) => {
        const activeOrder = orderState.find(o => o.state !== null);
        if (activeOrder) {
            switch (activeOrder.label) {
                case 'Name':
                    return sortByName(activeOrder.state === 'DESC');
                case 'Songs':
                    return sortBySongs(links ?? [], activeOrder.state === 'DESC');
            }
        }
        return sortByName(false);
    }

    const getFilter = (filterStr: string) => {
        return (el: Champion) => filterStr.length === 0 || el.name.search(new RegExp(filterStr, 'i')) >= 0
    }

    const handleChampionClick = (championId: number) => {
        setModalChampionId(championId)
    }

    useEffect(() => {
        BackendProvider({ apiAddress })
            .fetchLinksByChampion()
            .then(data => setLinks(data));
    }, [changeFlag])

    return (
        <div className="championcollection-frame">
            <h1>Champions</h1>
            <div className="filters">
                <input className="champion-filter" placeholder="Search" value={filter} onChange={e => setFilter(e.target.value)} autoFocus={true}></input>
                { orderState.map((order, index) => <OrderButton {...order} key={index} onClick={state => handlerOrderClick(order.label, state)}/>)}
            </div>
            <div className="championcollection-list">
                { Object.values(props.dataContext.champions)
                    .filter(getFilter(filter))
                    .sort(getOrderAlgorithm(orderState))
                    .map((champion, index) => (
                        <Champion
                            icon={<ChampionIcon {...champion} version={props.dataContext.version ?? ''} style={{width: '3em'}}/>}
                            songCounter={links === null ? null : links.find(link => link.championId === parseInt(champion.key))?.songs.length ?? 0}
                            onClick={() => handleChampionClick(parseInt(champion.key))}
                            key={index}
                        />
                ))}
            </div>
            { modalChampionId !== null &&
                <Modal
                    element={
                        <SongPicker
                            champion={Object.values(props.dataContext.champions).find(champion => parseInt(champion.key) === modalChampionId)}
                            selectedSongs={links?.find(link => link.championId === modalChampionId)?.songs ?? []}
                            dataContext={props.dataContext}
                            authContext={props.authContext}
                            onDataUpdate={() => setChageFlag(changeFlag + 1)}
                        />}
                    onCancel={() => setModalChampionId(null)}
                />
            }
        </div>
    )
}