import { FormEvent, useState } from 'react'
import { AuthContext } from '../../App'
import { storageKey } from './AutoLogin';
import './style.sass'

const apiAddress: string = process.env.REACT_APP_API_ADDRESS ?? '';

function LogIn(prop: object) {
    const [password, setPassword] = useState('');

    
    const tryLogIn = (event: FormEvent, setApiToken: (token: string)=> void) => {
        fetch(`${apiAddress}/auth?pwd=${password}`, {
            mode: 'cors'
        })
        .then(response => response.json())
        .then(body => {
            sessionStorage.setItem(storageKey, body.token);
            setApiToken(body.token);
        });

        event.preventDefault();
    }

    const logOut = (setApiToken: (token: string | null)=> void) => {
        sessionStorage.removeItem(storageKey);
        setApiToken(null);
    }

    return (
        <div>
            <AuthContext.Consumer>
            {({ apiToken, setApiToken }) => (
                <div>
                    { apiToken === null &&
                        <div>
                            <h1>Please log in to save, modify, or delete anything</h1>
                            <form onSubmit={(e) => { tryLogIn(e, setApiToken) }}>
                                <input type="password" className="login-password-input" placeholder="Password" autoComplete="password" id="login-input"
                                       value={password} onChange={e => setPassword(e.target.value)}></input>
                                <button type="submit" id="login-submit-button" >Log in</button>
                            </form>
                            
                        </div>
                    }
                    { apiToken !== null &&
                        <div>
                            <h1>You are logged in.</h1>
                            <p>If saving data doesn't work, re-login might be worth trying.</p>
                            <button type="button" onClick={() => logOut(setApiToken)}>Log out</button>
                        </div>
                    }
                </div>
            )}
            </AuthContext.Consumer>
        </div>
    )
}

export default LogIn
