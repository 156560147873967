import { useState } from 'react';
import YoutubeIcon from '../YoutubeIcon';
import './style.sass';

export const decodeHtml = (input: string) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = input;
    return txt.value;
}

function YoutubeSearchResult(prop: { onClick(): boolean } & Item) {
    const [added, setAdded] = useState<boolean>(false);

    const onClick = () => {
        setAdded(prop.onClick());
    }

    return (
        <div className={`youtube-link-row ${added ? 'added' : ''}`} onClick={onClick}>
            <a href={`https://youtu.be/${prop.id.videoId}`} target="_blank" rel="noreferrer" title="Open in Youtube" onClick={e => e.stopPropagation()}>
                <YoutubeIcon width="1.1em"/>
            </a>
            <span>{decodeHtml(prop.snippet.title)}</span>
        </div>
    )
}

export default YoutubeSearchResult;
