import SongCollection from "../../SongCollection/SongCollection";

function SongsPage(prop: object) {
    return (
        <div>
            <SongCollection></SongCollection>
        </div>
        
    )
}

export default SongsPage;
