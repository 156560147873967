import { AuthContext } from "../../../contexts/AuthContext";
import { LolDataContext } from "../../../contexts/LolDataContext";
import { ChampionCollection } from "../../ChampionCollection/ChampionCollection";


function ChampionsPage(prop: object) {
    return (
        <AuthContext.Consumer>
            { authContext => 
                <LolDataContext.Consumer>
                    { dataContext =>
                        <ChampionCollection authContext={authContext} dataContext={dataContext} />
                    }
                </LolDataContext.Consumer>
            }
        </AuthContext.Consumer>
    )
}

export default ChampionsPage;
